import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  mainContent: {
    // width: "812px",
    // margin: "0 auto"
    justifyContent: "center",
    margin: "0 8% 0 8%"
  },
  title: {
    marginTop: "35px",
    marginBottom: "15px",
    fontSize: "32px",
    fontWeight: "400",
    lineHeight: "40px"
  },

  extTitle: {
    marginTop: "57px",
    marginBottom: "15px",
    fontSize: "32px",
    fontWeight: "400",
    lineHeight: "40px"
  },

  desktopWelcome: {
    marginBottom: "17px",
  },

  // Mobile View
  titleMobile: {
    width: "327px",
    display: "flex",
    justifyContent: "flex-start",
    margin: "50px 0px 10px 0px",
    fontSize: "24px",
    fontWeight: "400",
   //lineHeight: "32px"
  },
  extTitleMobile: {
    width: "327px",
    display: "flex",
    justifyContent: "flex-start",
    margin: "50px 0px 10px 0px",
    fontSize: "24px",
    fontWeight: "400",
   //lineHeight: "32px"
  },
  mainContentMobile: {
    minWidth: "330px",
    margin: "0 auto",
    justifyContent: "center",
  },

  mobileWelcome: {
    margin: "0 auto",
  },
});
