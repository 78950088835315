import CourseCard from "./CourseCard";
import React, { useState, useEffect } from "react";
import FacultyPortalStyles from "../../../../styles.js";
import styles from "./CourseCardDisplay.styles";
import { connect } from "react-redux";
import NoCurrentCourses from "./NoCurrentCourses.jsx";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper";
import facultyPortalTheme from "../../../../facultyPortalTheme.js";
import { storeErrorMessages } from "../../../../redux/displayApiErrors/displayApiErrorsActions";
import { getExtendedCoursesForIrn } from "../../../../redux/studentAccommodations/studentAccommodationsActions"; // make non redux version


const ExtInProgress = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const facultyClasses = FacultyPortalStyles();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [ccOpenSuccess, setCcOpenSuccess] = useState(false);
  
  let courseCardKeyCount = 0;

  useEffect(() => {
    if(props.demographics.issuerId){
    props.getExtendedCoursesForIrn(props.demographics.issuerId);
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Below Code deals with Snackbar
  // -------------------------------------------------------------------------------------------------------------
  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={
          openSuccess
            ? true
            : ccOpenSuccess
        }
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.issuerId) {
        props.getExtendedCoursesForIrn(props.demographics.issuerId);
      }
    }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      {ccOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
      {props.extendedCourses.length >= 1 ? (
        props.extendedCourses
          .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
          .map((extendedCourse) => {
          
            return (
              <div
                className={extendedCourse.courseId === null ? classes.hide : ""}
                key={courseCardKeyCount++}
              >
                <CourseCard
                  matches={matches}
                  type="extendedCourses"
                  isExtInProgress = {true}
                  currentCourse={extendedCourse}
                />
              </div>
            );
          })
      ) : (
        <NoCurrentCourses />
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    errorMessages: state.errorMessages,
    extendedCourses: state.extendedCourses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExtendedCoursesForIrn: (irn) =>
      dispatch(getExtendedCoursesForIrn(irn)), 
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtInProgress);