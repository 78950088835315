import { React, useState, useEffect } from "react";
import styles from "../styles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../facultyPortalTheme";
import {
  useMediaQuery,
  Box,
  Grid,
  Link,
  Typography,
  TableCell,
  Modal,
} from "@material-ui/core";
import BlackArrow from "../../../images/BlackArrow.svg";
// import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CourseTableCard from "./CourseTableCard";
import ActionNeeded from "./ActionNeeded";
import { getStudentAccommodations } from "../../../redux/studentAccommodations/studentAccommodationsActions";
import { getFacultyCurrentCourses } from "../../../services/faculty/v1/faculty";
import { getPersonInfoWithName } from "../../../redux/demographics/demographicsActions";
import { selectPersonByIrnAndType } from "../../../redux/demographics/personsWithNameSelector";

const ManageAccommodations = (props) => {
  document.title = "Faculty Portal Manage Accommodations Page";
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));
  // const location = useLocation();

  const [courses, setCourses] = useState([]); // Main course list, where each course object will make up a course table
  const [alreadyAcknowledged, setAlreadyAcknowledged] = useState(); // Used for popup. Distinguish between "Reveiw FNF", and new accommodation
  const [update, setUpdate] = useState({
    studentIrn: "",
    courseOfferingId: ""
  }); // Needed for after acknowledgment, page will refresh


  // =========================================================================================================================
  // useState that is used for ActionsNeeded.jsx component and calling its respective POST accommodations endpoint.  Function
  // to close modal.
  const [openModal, setOpenModal] = useState(false);
  const [studentToAcknowledge, setStudentToAcknowledge] = useState({});
  const [studentNotesAcknowledging, setStudentNotesAcknowledging] = useState("");
  const [studentGradePostAcknowledging, setStudentGradePostAcknowledging] = useState();
  const [studentPermAccommodations, setStudentPermAccommodations] = useState(""); // Used for SRM cases. Permanent accommodations
  const [studentTempAccommodations, setStudentTempAccommodations] = useState([]); // Used for SRM cases. Temporary accommodations
  const [srmStudentSpecialArrangements, setSrmStudentSpecialArrangements] = useState(""); // Used to in SRM cases to get special arrangements for Perm accommodations

  const [courseAcknowledging, setCourseAcknowledging] = useState("");
  const [courseOfferingIdAcknowledging, setCourseOfferingIdAcknowledging] = useState("");
  const [courseStartDateAcknowledging, setCourseStartDateAcknowledging] = useState("");
  const [courseEndDateAcknowledging, setCourseEndDateAcknowledging] = useState("");

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpenModal(false);
  };
  // =========================================================================================================================


  // =========================================================================================================================
  // Call current courses endpoint and create list of courseOfferingId's and studentIrns, with accommodations, that are within
  // each current course of the faculty.
  const [currentCourses, setCurrentCourses] = useState([]);
  const [courseOfferingIdList, setCourseOfferingIdList] = useState([]);
  const [studentIrnList, setStudentIrnList] = useState([]);
 
  let currentCoursesData = [];
  let currentCoursesRetStatus = 0;

  const getCurrentCourses = async (irn) => {
    await getFacultyCurrentCourses(irn)
      .then((response) => {
        currentCoursesData = response.data;
        currentCoursesRetStatus = response.status;
        let tempCourseOfferingIdList = [];
        let tempStudentIrnList = [];
        for (let i = 0; i < currentCoursesData.length; i++) {
          tempCourseOfferingIdList = [...tempCourseOfferingIdList, currentCoursesData[i].courseOfferingId];
          tempStudentIrnList = tempStudentIrnList.concat(currentCoursesData[i].accommodatedStudentIrnList);
        }
        tempStudentIrnList = new Set(tempStudentIrnList);
        setCourseOfferingIdList(tempCourseOfferingIdList);
        setStudentIrnList(Array.from(tempStudentIrnList));
        setCurrentCourses(currentCoursesData);
        // updateAccommodations(currentCoursesData, currentCoursesRetStatus);
      })
      .catch((error) => {
        currentCoursesData = error.request;
        currentCoursesRetStatus = error.status;
      });
  };

  useEffect(() => {
    if (
      props.facultyDemographics.issuerId !== null ||
      props.facultyDemographics.issuerId !== undefined
    ) {
      getCurrentCourses(props.facultyDemographics.issuerId);
    }
  }, [props.facultyDemographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps
  // =========================================================================================================================
  

  // =========================================================================================================================
  // Call get student acknowledgement endpoint.  Create an object to pass in with the get acknowledgement endpoint.
  const [getAcknowledgementObject, setGetAcknowledgementObject] = useState({});

  useEffect(() => {
    if (props.facultyDemographics.issuerId !== null || props.facultyDemographics.issuerId !== undefined) {
      if (studentIrnList.length > 0) {
        setGetAcknowledgementObject({
          facultyIrn: props.facultyDemographics.issuerId,
          studentIrns: studentIrnList,
          courseOfferingIds: courseOfferingIdList,
        })
      }
    }
  }, [props.facultyDemographics, courseOfferingIdList, studentIrnList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(getAcknowledgementObject).length > 0) {
      props.getStudentAccommodations(getAcknowledgementObject);
    }
  }, [getAcknowledgementObject, update]); // eslint-disable-line react-hooks/exhaustive-deps
  // =========================================================================================================================


  // =========================================================================================================================
  // Use personName endpoint to create a list of persons for each student, using studentIrnList
  const [studentFullName, setStudentFullName] = useState("");
  const [studentPersonId, setStudentPersonId] = useState("");

  const studentPersonType = "student";

  const handleGetPersonInfoWithName = (irn, personType) => {
    props.getPersonInfoWithName(irn, personType);
  };

  useEffect(() => {
    for (let i = 0; i < studentIrnList.length; i++) {
      handleGetPersonInfoWithName(studentIrnList[i], studentPersonType);
    }
  }, [studentIrnList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.student && props.student.irn) {
      const studentPerson = selectPersonByIrnAndType(
        props.personsWithName,
        props.student.irn,
        studentPersonType
      );
      if (studentPerson) {
        setStudentPersonId(studentPerson.id);
        setStudentFullName(
          `${studentPerson.firstName} ${studentPerson.lastName}`
        );
        return;
      }
    } else {
      setStudentPersonId(null);
      setStudentFullName("");
    }
  }, [studentIrnList]); // eslint-disable-line react-hooks/exhaustive-deps
  // =========================================================================================================================


  // =========================================================================================================================
  // Iterating through get Acknowledgement endpoint, personNames list, studentIrnList, current Courses list, and SRM endpoint.
  useEffect(() => {
    if (props.facultyDemographics.issuerId !== null || props.facultyDemographics.issuerId !== undefined) {
      if (
        Object.keys(props.personsWithName.personsWithNameMap).length !== 0 && studentIrnList.length !== 0
      ) {
        let tempCourses = [];
        if (Object.keys(props.personsWithName.personsWithNameMap).length === studentIrnList.length) {
          for (let i = 0; i < currentCourses.length; i++) {
            let courseObject = {};
            courseObject.courseId = `${currentCourses[i].courseId}`;
            courseObject.courseTitle = `${currentCourses[i].courseTitle}`;
            courseObject.groupId = `${currentCourses[i].groupId}`;
            courseObject.startDate = `${currentCourses[i].startDate}`;
            courseObject.endDate = `${currentCourses[i].endDate}`;
            courseObject.offeringType = `${currentCourses[i].offeringType}`;
            courseObject.courseOfferingId = `${currentCourses[i].courseOfferingId}`;
            let tempStudentList = [];
            const courseStudentIrnList = currentCourses[i].accommodatedStudentIrnList !== undefined ? 
              currentCourses[i].accommodatedStudentIrnList : [];
            for (let j = 0; j < courseStudentIrnList.length; j++) {
              let foundInGetAccommodations = false
              for (let k = 0; k < props.studentAccommodations.length; k++) {
                if (
                  // props.studentAccommodations[k].facultyIrn !== 0 &&
                  props.studentAccommodations[k].courseOfferingId !== 0 &&
                  `${props.studentAccommodations[k].studentIrn}` === `${courseStudentIrnList[j]}`
                ) {
                  const studentKey = `${courseStudentIrnList[j]}-student`;
                  let studentObject = {};

                  studentObject.studentAccommodationInfoId = `${props.studentAccommodations[k].studentAccommodationInfoId}`;
                  studentObject.studentIrn = `${props.personsWithName.personsWithNameMap[studentKey].issuerId}`;
                  studentObject.studentPersonId = `${props.personsWithName.personsWithNameMap[studentKey].id}`;
                  studentObject.fullName = 
                    `${props.personsWithName.personsWithNameMap[studentKey].firstName} ${props.personsWithName.personsWithNameMap[studentKey].lastName}`;
                  studentObject.notes = `${props.studentAccommodations[k].notes}`;
                  studentObject.isFinalGradePosted = `${props.studentAccommodations[k].isFinalGradePosted}`;
                  studentObject.dateCreated = `${props.studentAccommodations[k].dateCreated}`;
                  studentObject.createdBy = `${props.studentAccommodations[k].createdBy}`;
                  studentObject.dateModified = `${props.studentAccommodations[k].dateModified}`;
                  studentObject.modifiedBy = `${props.studentAccommodations[k].modifiedBy}`;
                  studentObject.facultyIrn = `${props.facultyDemographics.issuerId}`;
                  studentObject.facultyPersonId = `${props.facultyDemographics.personId}`;                  
                  studentObject.courseOfferingId = `${props.studentAccommodations[k].courseOfferingId}`;
                  studentObject.newAccommodation = null;
                  studentObject.receivingFromFwsDatabase = true;
                  studentObject.facultyAcknowledgements = props.studentAccommodations[k].facultyAcknowledgements;

                  tempStudentList = [...tempStudentList, studentObject];
                  foundInGetAccommodations = true;
                  break;
                }
                else if (
                  // props.studentAccommodations[k].facultyIrn === 0 &&
                  props.studentAccommodations[k].courseOfferingId === 0 &&
                  `${props.studentAccommodations[k].studentIrn}` === `${courseStudentIrnList[j]}`
                ) {
                  const studentKey = `${courseStudentIrnList[j]}-student`;
                  let studentObject = {};

                  studentObject.studentAccommodationInfoId = `${props.studentAccommodations[k].studentAccommodationInfoId}`;
                  studentObject.studentIrn = `${props.personsWithName.personsWithNameMap[studentKey].issuerId}`;
                  studentObject.studentPersonId = `${props.personsWithName.personsWithNameMap[studentKey].id}`;
                  studentObject.fullName = 
                    `${props.personsWithName.personsWithNameMap[studentKey].firstName} ${props.personsWithName.personsWithNameMap[studentKey].lastName}`;
                  studentObject.notes = `${props.studentAccommodations[k].notes}`;
                  studentObject.isFinalGradePosted = `${props.studentAccommodations[k].isFinalGradePosted}`;
                  studentObject.dateCreated = `${props.studentAccommodations[k].dateCreated}`;
                  studentObject.createdBy = `${props.studentAccommodations[k].createdBy}`;
                  studentObject.dateModified = `${props.studentAccommodations[k].dateModified}`;
                  studentObject.modifiedBy = `${props.studentAccommodations[k].modifiedBy}`;
                  studentObject.facultyIrn = `${props.facultyDemographics.issuerId}`;
                  studentObject.facultyPersonId = `${props.facultyDemographics.personId}`;
                  studentObject.courseOfferingId = `${props.studentAccommodations[k].courseOfferingId}`;
                  studentObject.newAccommodation = true;
                  studentObject.receivingFromFwsDatabase = true;
                  studentObject.facultyAcknowledgements = props.studentAccommodations[k].facultyAcknowledgements;

                  tempStudentList = [...tempStudentList, studentObject];
                  foundInGetAccommodations = true
                  break;
                }
              }
              if (!foundInGetAccommodations) {
                const studentKey = `${courseStudentIrnList[j]}-student`;
                let studentObject = {};

                studentObject.studentAccommodationInfoId = "0";
                studentObject.studentIrn = `${props.personsWithName.personsWithNameMap[studentKey].issuerId}`;
                studentObject.studentPersonId = `${props.personsWithName.personsWithNameMap[studentKey].id}`;
                studentObject.fullName = 
                  `${props.personsWithName.personsWithNameMap[studentKey].firstName} ${props.personsWithName.personsWithNameMap[studentKey].lastName}`;
                studentObject.notes = "";
                studentObject.isFinalGradePosted = false;
                studentObject.dateCreated = "";
                studentObject.createdBy = "";
                studentObject.dateModified = "";
                studentObject.modifiedBy = "";
                studentObject.facultyIrn = `${props.facultyDemographics.issuerId}`;
                studentObject.facultyPersonId = `${props.facultyDemographics.personId}`;
                studentObject.courseOfferingId = "0";
                studentObject.newAccommodation = true;
                studentObject.receivingFromFwsDatabase = false;
                studentObject.facultyAcknowledgements = [];

                tempStudentList = [...tempStudentList, studentObject];
              }
            }

            courseObject.studentList = tempStudentList;
            tempCourses = [...tempCourses, courseObject];
          } 
          setCourses(tempCourses);
        }
      }
    }
  }, [props.personsWithName, props.studentAccommodations, currentCourses, props.facultyDemographics, update]); // eslint-disable-line react-hooks/exhaustive-deps
  // =========================================================================================================================


  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Box
        className={
          matches
            ? classes.pageContainer
            : tabletMatches
            ? classes.pageContainerTablet
            : classes.pageContainerMobile
        }
      >
        <Grid
          item
          className={
            matches
              ? classes.linkContainer
              : tabletMatches
              ? classes.linkContainerTablet
              : classes.linkContainerMobile
          }
        >
          <Link
            id="backToDashboardLink"
            data-testid="back_to_dashboard_link"
            underline="none"
            className={classes.backToDashboardLink}
            href="/"
          >
            <img className={classes.arrowImage} src={BlackArrow} alt="" />
            <Typography>{`Course Dashboard`}</Typography>
          </Link>
        </Grid>
        <div
          style={{
            maxWidth: "1232px", // Maximum width for large screens
            width: "86%", // Leaves a 7% margin on each side (100% - 2 * 7%)
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "8%",
              marginTop: "1%",
            }}
          >
            <Typography
              id="pageHeader"
              data-testid="page_header"
              component="h1"
              className={
                matches
                  ? classes.pageHeader
                  : tabletMatches
                  ? classes.pageHeaderTablet
                  : classes.pageHeaderMobile
              }
            >
              {"Manage accommodations"}
            </Typography>
          </Box>
          <Box className={classes.mainContentContainer}>
            <Typography
              component="p"
              className={classes.newFont}
              style={{ marginBottom: "24px" }}
            >
              {`University of Phoenix is required to prohibit discrimination and to provide reasonable accommodations and modifications to qualified students in all University programs and activities under the law.`}
            </Typography>
            <Typography component="p" className={classes.newFont}>
              {`As faculty of University of Phoenix, you are responsible for implementing the approved accommodations for students in class. Accommodations are determined after a deliberative and interactive process between the student and University staff. Accommodations must be provided as indicated, following all guidelines and directives provided in the accommodation notification.`}
            </Typography>
          </Box>
          {courses.length > 0 ? (
            courses.map((course) => (
              <CourseTableCard
                courseId={course.courseId}
                courseTitle={course.courseTitle}
                groupId={course.groupId}
                courseStartDate={course.startDate}
                courseEndDate={course.endDate}
                offeringType={course.offeringType}
                courseOfferingId={course.courseOfferingId}
                students={course.studentList}
                setOpenModal={setOpenModal}
                setAlreadyAcknowledged={setAlreadyAcknowledged}
                setStudentToAcknowledge={setStudentToAcknowledge}
                setStudentNotesAcknowledging={setStudentNotesAcknowledging}
                setStudentGradePostAcknowledging={setStudentGradePostAcknowledging}
                setStudentPermAccommodations={setStudentPermAccommodations}
                setStudentTempAccommodations={setStudentTempAccommodations}
                setSrmStudentSpecialArrangements={setSrmStudentSpecialArrangements}
                setCourseAcknowledging={setCourseAcknowledging}
                setCourseOfferingIdAcknowledging={setCourseOfferingIdAcknowledging}
                setCourseStartDateAcknowledging={setCourseStartDateAcknowledging}
                setCourseEndDateAcknowledging={setCourseEndDateAcknowledging}
                update={update}
              />
            ))
          ) : (
            // <>
            //   <CourseTableCard students={tempStudents} setOpenModal={setOpenModal} />
            //   <CourseTableCard students={tempStudents} setOpenModal={setOpenModal} />
            // </>
            <>
              <TableCell colSpan={6} className={classes.emptyStateContainer}>
                <Typography className={classes.noStudentsText}>
                  You currently do not have any accommodated students.
                </Typography>
              </TableCell>
            </>
          )}
        </div>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        disableEscapeKeyDown // This disables closing the modal with the Escape key
        className={classes.actionModal}
      >
        <div className={classes.modalContent}>
          <ActionNeeded 
            setOpenModal={setOpenModal}
            studentToAcknowledge={studentToAcknowledge}
            studentNotesAcknowledging={studentNotesAcknowledging}
            studentGradePostAcknowledging={studentGradePostAcknowledging}
            studentPermAccommodations={studentPermAccommodations}
            studentTempAccommodations={studentTempAccommodations}
            srmStudentSpecialArrangements={srmStudentSpecialArrangements}
            courseAcknowledging={courseAcknowledging}
            courseOfferingIdAcknowledging={courseOfferingIdAcknowledging}
            courseStartDateAcknowledging={courseStartDateAcknowledging}
            courseEndDateAcknowledging={courseEndDateAcknowledging}
            alreadyAcknowledged={alreadyAcknowledged}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    facultyDemographics: state.demographics,
    facultyContacts: state.facultyContacts,
    studentAccommodations: state.studentAccommodations,
    personsWithName: state.personsWithName,
    srmStudentAccommodations: state.studentApiAccommodations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentAccommodations: (getAcknowledgentObject) =>
      dispatch(getStudentAccommodations(getAcknowledgentObject)), //Retrieving accommodations from FWS
    getPersonInfoWithName: (irn, personType) =>
      dispatch(getPersonInfoWithName(irn, personType)), //Retrieving student names
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccommodations);
