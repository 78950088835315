import { getAccommodations, getExtendedCourses } from "../../services/faculty/v1/faculty";
import { GET_STUDENT_ACCOMMODATIONS,  GET_EXTENDED_COURSES} from "./actionTypes";

export const getStudentAccommodations = (getAcknowledgementObject) => async (dispatch) => {
  await  getAccommodations(getAcknowledgementObject)
    .then((response) => {
      dispatch({ type: GET_STUDENT_ACCOMMODATIONS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_STUDENT_ACCOMMODATIONS, payload: error.request });
    });
};

export const getExtendedCoursesForIrn = (irn) => async (dispatch) => {
  await  getExtendedCourses(irn)
    .then((response) => {
      dispatch({ type: GET_EXTENDED_COURSES, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_EXTENDED_COURSES, payload: error.request });
    });
};