import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import csrf from "./csrf/reducer";
import solicitationsReducer from "./solicitations/solicitationsReducer";
import acceptedSolicitationsReducer from "./solicitations/acceptedSolicitationsReducer";
import declinedSolicitationsReducer from "./solicitations/declinedSolicitationsReducer";
import supervisorReducer from "./supervisor/supervisorReducer";
import currentCoursesReducer from "./currentCourses/currentCoursesReducer";
import coursesPreferencesReducer from "./coursesPreferences/coursesPreferencesReducer";
import registerChangeReducer from "./coursesPreferences/registerChangeReducer";
import courseHistoryReducer from "./courseHistory/courseHistoryReducer";
import declineReasonsReducer from "./declineReasons/declineReasonsReducer";
import demographicsReducer from "./demographics/demographicsReducer";
import facultyContactsReducer from "./demographics/facultyContactsReducer";
import setChangedPreferencesReducer from "./coursesPreferences/setChangedPreferencesReducer";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import reduxThunk from "redux-thunk";
import preloadedState from "./preloadedState";
import userManager from "../utils/userManager";
import schedulerReducer from "./scheduler/schedulerReducer";
import modifiedCourseHistoryReducer from "./courseHistory/modifiedCourseHistoryReducer";
import errorReducer from "./coursesPreferences/errorReducer";
import navigateOffReducer from "./coursesPreferences/navigateOffReducer";
import updateNotificationsReducer from "./notifications/updateNotificationsReducer";
import displayApiErrorsReducer from "./displayApiErrors/displayApiErrorsReducer";
import responseSolicitationReducer from "./solicitations/responseSolicitationReducer";
import studentProfileReducer from "./earlyAlerts/studentProfileReducer";
import courseOfferingReducer from "./earlyAlerts/courseOfferingReducer";
import feocsInviteLinkReducer from "./feocsInviteLink/feocsInviteLinkReducer";
import workshopsReducer from "./workshops/workshopsReducer";
import sectionsForIrnReducer from "./workshops/sectionsForIrnReducer";
import helpDeskCaseTypeReducer from "./helpDeskSubmission/helpDeskCaseTypeReducer";
import helpDeskIssuetypeReducer from "./helpDeskSubmission/helpDeskIssuetypeReducer";
import siteStripeMessageReducer from "./siteStripe/siteStripeMessageReducer";
import personsWithNameReducer from "./demographics/personsWithNameReducer";
import studentAccommodationsReducer from "./studentAccommodations/studentAccommodationsReducer";
import extendedCoursesReducer from "./studentAccommodations/extendedCoursesReducer";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

/* const loggerMiddleware = store => next => action => {
  console.log("Action type:", action.type);
  console.log("Action payload:", action.payload);
  console.log("State before:", store.getState());
  next(action);
  console.log("State after:", store.getState());
}; */

const store = createStore(
  combineReducers({
    csrf,
    workshops: workshopsReducer,
    sectionsForIrn: sectionsForIrnReducer,
    supervisor: supervisorReducer,
    solicitations: solicitationsReducer,
    responseSolicitation: responseSolicitationReducer,
    acceptedSolicitations: acceptedSolicitationsReducer,
    declinedSolicitations: declinedSolicitationsReducer,
    courseHistory: courseHistoryReducer,
    modifiedCourseHistory: modifiedCourseHistoryReducer,
    currentCourses: currentCoursesReducer,
    coursesPreferences: coursesPreferencesReducer,
    registerChange: registerChangeReducer,
    declineReasons: declineReasonsReducer,
    demographics: demographicsReducer,
    facultyContacts: facultyContactsReducer,
    setChangedPreferences: setChangedPreferencesReducer,
    scheduler: schedulerReducer,
    feocsInviteLink: feocsInviteLinkReducer,
    studentProfile: studentProfileReducer,
    courseOffering: courseOfferingReducer,
    caseTypes: helpDeskCaseTypeReducer,
    issueTypes: helpDeskIssuetypeReducer,
    router: connectRouter(history),
    errors: errorReducer,
    navigateOff: navigateOffReducer,
    updateNotifications: updateNotificationsReducer,
    errorMessages: displayApiErrorsReducer,
    siteStripe: siteStripeMessageReducer,
    studentAccommodations: studentAccommodationsReducer,
    extendedCourses : extendedCoursesReducer,
    oidc: oidcReducer,
    personsWithName: personsWithNameReducer,
  }),
  preloadedState,
  // composeEnhancers(applyMiddleware(reduxThunk, routerMiddleware(history), loggerMiddleware))
  composeEnhancers(applyMiddleware(reduxThunk, routerMiddleware(history)))
);

loadUser(store, userManager);

/* const saveState = (state) => {
    try {
        // Convert the state to a JSON string
        const serialisedState = JSON.stringify(state);

        // Save the serialised state to localStorage against the key 'app_state'
        window.localStorage.setItem('app_state', serialisedState);
    } catch (err) {
        // Log errors here, or ignore
    }
};

store.subscribe(() => {
    saveState(store.getState());
});

const loadState = () => {
    try {
        // Load the data saved in localStorage, against the key 'app_state'
        const serialisedState = window.localStorage.getItem('app_state');

        // Passing undefined to createStore will result in our app getting the default state
        // If no data is saved, return undefined
        if (!serialisedState) return undefined;

        // De-serialise the saved state, and return it.
        return JSON.parse(serialisedState);
    } catch (err) {
        // Return undefined if localStorage is not available,
        // or data could not be de-serialised,
        // or there was some other error
        return undefined;
    }
};

loadState(); */

export default store;
