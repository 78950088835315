import { proxyClientInstance } from "../../wrappers/client";

export const getService = () => {
    const url = "/api/student/health";
    return proxyClientInstance.get(url);
};

export const getStudentApiAccommodations = (personId) => {
    const url = `/api/student/v1/accommodations/${personId}`;
    return proxyClientInstance.get(url);
};