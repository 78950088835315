import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  Link,
  Button,
  IconButton,
  InputAdornment, 
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import SaveIcon from '@mui/icons-material/Save';
import styles from "../styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { connect, useSelector } from "react-redux";
import { getPersonInfoWithName } from "../../../redux/demographics/demographicsActions";
import { getStudentApiAccommodations } from "../../../services/student/v1/student";
import { updateStudentAccommodation } from "../../../services/faculty/v1/faculty";

function CourseTableRow(props) {
  const useStyles = styles;
  const classes = useStyles();
  const state = useSelector((state) => state);

  const [originalNote, setOriginalNote] = useState(props.student.notes === "null" ? "" : props.student.notes);
  const [noteValue, setNoteValue] = useState(props.student.notes === "null" ? "" : props.student.notes);
  const [noteHasChanged, setNoteHasChanged] = useState(false);
  const [disabilityTypes, setDisabilityTypes] = useState("");
  const [accommodationTypesAcknowledged, setAccommodationTypesAcknowledged] = useState([]);
  const [newAccommodationTypes, setNewAccommodationTypes] = useState([]);

  const [studentFullName, setStudentFullName] = useState("");
  const [studentPersonId, setStudentPersonId] = useState("");

  const [focused, setFocused] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  const [phoenixTimestamp, setPhoenixTimeStamp] = useState(getPhoenixTimestamp());                                                                                        
  const [gradeValue, setGradeValue] = useState(props.student.isFinalGradePosted === "true" ? true : false); 
  const [accommodationObject, setAccommodationObject] = useState({});


  useEffect(() => {
    setAccommodationObject({
      id: props.student.studentAccommodationInfoId,
      facultyIRN: props.facultyDemographics.issuerId,
      facultyPersonId: props.facultyDemographics.personId,
      studentIRN: props.student.studentIRN,
      studentPersonId: props.student.studentPersonId,
      courseOfferingId: props.student.courseOfferingId,
      // accommodationType: "help",
      notes:  props.student.notes,
      isFinalGradePosted:  props.student.isFinalGradePosted,
      // dateAcknowledged: "2024-09-27 13:27:00",
      // disabilityType: "2323",
      dateCreated: props.student.dateCreated,
      dateModified: props.student.dateModified, // will need to update with actual timestamp
      modifiedBy: props.student.modifiedBy, // will need to update by personId (logged in faculty)
    });
  }, [props.student])

  useEffect(() => {
    if (props.update.studentIrn === props.student.studentIrn && props.update.courseOfferingId === props.courseOfferingId) {
      setHasNewAccommodation(false);
    };
  }, [props.update])

  // =========================================================================================================================
  // Call for SRM endpoint if student information does not come from fws database.
  const [studentSrmApiInfo, setStudentSrmApiInfo] = useState({});

  const handleSrmApiEndpoint = async (personId) => {
    await getStudentApiAccommodations(personId)
      .then((response) => {
        setStudentSrmApiInfo(response);
      })
      .catch((error) => {
        console.log(error.request);
      })
  };

  useEffect(() => {
    if (!props.student.receivingFromFwsDatabase) {
      handleSrmApiEndpoint(props.student.studentPersonId);
    }
  }, []);
  // =========================================================================================================================

  // =========================================================================================================================
  // Set new and already acknowledged acknowledgements to display to the the screen for student.
  const [hasNewAccommodation,  setHasNewAccommodation] = useState(false);

  useEffect(() => {
    if (props.update.studentIrn !== props.student.studentIrn && props.update.courseOfferingId !== props.courseOfferingId) {
      if (props.student.receivingFromFwsDatabase) {
        if (props.student.courseOfferingId !== 0) {
          if (
            props.student.facultyAcknowledgements !== undefined || 
            props.student.facultyAcknowledgements !== null
          ) {
            let tempAccommodationTypesAcknowledged = [];
            let tempNewAccommodationTypes = [];
      
            if (props.student.facultyAcknowledgements.length >= 1) { 
              props.student.facultyAcknowledgements.forEach((accommodation) => {
                if (accommodation.accommodationStartDate === "null" && accommodation.accommodationEndDate === "null") {
                  if (accommodation.dateAcknowledged === null) {
                    tempNewAccommodationTypes.push(accommodation.accommodationType);
                    setHasNewAccommodation(true);
                  } else {
                    tempAccommodationTypesAcknowledged.push(accommodation.accommodationType);
                  }
                } else {
                  const accommodationStartDate = new Date(accommodation.accommodationStartDate);
                  const accommodationEndDate = new Date(accommodation.accommodationEndDate);
                  if (accommodationStartDate <= new Date() && accommodationEndDate >= new Date()) {
                    if (accommodation.dateAcknowledged === null) {
                      tempNewAccommodationTypes.push(accommodation.accommodationType);
                      setHasNewAccommodation(true);
                    } else {
                      tempAccommodationTypesAcknowledged.push(accommodation.accommodationType);
                    }
                  }
                }
              });
            };
      
            setDisabilityTypes("ADA");
            setNewAccommodationTypes(tempNewAccommodationTypes);
            setAccommodationTypesAcknowledged(tempAccommodationTypesAcknowledged);
          };
        } else {
          if (
            props.student.facultyAcknowledgements !== undefined || 
            props.student.facultyAcknowledgements !== null
          ) {
            let tempAccommodationTypesAcknowledged = [];
            let tempNewAccommodationTypes = [];
      
            if (props.student.facultyAcknowledgements.length >= 1) { 
              props.student.facultyAcknowledgements.forEach((accommodation) => {
                if (accommodation.accommodationStartDate === "null" && accommodation.accommodationEndDate === "null") {
                  if (accommodation.dateAcknowledged === null) {
                    tempNewAccommodationTypes.push(accommodation.accommodationType);
                  } else {
                    tempAccommodationTypesAcknowledged.push(accommodation.accommodationType);
                  }
                } else {
                  const accommodationStartDate = new Date(accommodation.accommodationStartDate);
                  const accommodationEndDate = new Date(accommodation.accommodationEndDate);
                  if (accommodationStartDate <= new Date() && accommodationEndDate >= new Date()) {
                    if (accommodation.dateAcknowledged === null) {
                      tempNewAccommodationTypes.push(accommodation.accommodationType);
                    } else {
                      tempAccommodationTypesAcknowledged.push(accommodation.accommodationType);
                    }
                  }
                }
              });
            };
      
            setDisabilityTypes("ADA");
            setHasNewAccommodation(true);
            setNewAccommodationTypes(tempNewAccommodationTypes);
            setAccommodationTypesAcknowledged(tempAccommodationTypesAcknowledged);
          };
        }
      } else {
        if (studentSrmApiInfo.status === 200) {
          let tempNewAccommodationTypes = [];
          let srmAccommodations = studentSrmApiInfo.data.accommodationType;
          let srmTempAccommodations = studentSrmApiInfo.data.tempAccoms
          srmAccommodations = srmAccommodations.split(";");
          tempNewAccommodationTypes = srmAccommodations;
          for (let i = 0; i < srmTempAccommodations.length; i++) {
            const accommodationEndDate = new Date(srmTempAccommodations[i].endDate);
            if (accommodationEndDate > new Date()) {
              tempNewAccommodationTypes.push(srmTempAccommodations[i].accommodationType);
            }
          }
          setHasNewAccommodation(true);
          setNewAccommodationTypes(tempNewAccommodationTypes);
          setAccommodationTypesAcknowledged([]);
          setDisabilityTypes("ADA");
          props.setSrmStudentSpecialArrangements(studentSrmApiInfo.data.specialArrangements);
          props.setStudentPermAccommodations(studentSrmApiInfo.data.accommodationType);
          props.setStudentTempAccommodations(studentSrmApiInfo.data.tempAccoms);
        }
      }
    }
  }, [studentSrmApiInfo, props.update, props.student]);

  function getPhoenixTimestamp() {
    const options = {
      timeZone: "America/Phoenix",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the current date in Phoenix time
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(new Date());

    // Extract date and time components
    const year = parts.find((part) => part.type === "year").value;
    const month = parts.find((part) => part.type === "month").value;
    const day = parts.find((part) => part.type === "day").value;
    const hour = parts.find((part) => part.type === "hour").value;
    const minute = parts.find((part) => part.type === "minute").value;
    const second = parts.find((part) => part.type === "second").value;

    // Construct the timestamp in "YYYY-MM-DD HH:MM:SS" format
    const phoenixTimestamp = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    return phoenixTimestamp;
  };

  const studentPersonType = "student";

  const handleNotesChange = (event) => {
    if (originalNote !== event.target.value) {
      setNoteHasChanged(true);
    } else {
      setNoteHasChanged(false);
    }

    setNoteValue(event.target.value);
  };

  const handleSaveNotes = () => {
    // endpoint call to save note goes here
    const timestamp = getPhoenixTimestamp();
  
    // Use a callback to ensure the state is fully updated before calling the API
    setAccommodationObject((prevState) => {
      // console.log(convertDate(timestamp))
      const updatedObject = {
        ...prevState,
        dateCreated: props.student.dateCreated,
        dateModified: convertDate(timestamp),
        modifiedBy: `${props.facultyDemographics.firstName} ${props.facultyDemographics.lastName}`,
        notes: noteValue,
      };
      
      // console.log(updatedObject);
      handleUpdateStudentAccommodation(updatedObject);
      
      return updatedObject;
    })
  };

  const handleOpenModal = () => {
    if (hasNewAccommodation) {
      props.setAlreadyAcknowledged(false);
    } else {
      props.setAlreadyAcknowledged(true);
    }
    props.setStudentToAcknowledge(props.student);
    props.setCourseAcknowledging(props.courseId);
    props.setCourseOfferingIdAcknowledging(props.courseOfferingId);
    props.setCourseStartDateAcknowledging(props.courseStartDate);
    props.setCourseEndDateAcknowledging(props.courseEndDate);
    props.setOpenModal(true);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleGradeChange = (event) => {
    const timestamp = getPhoenixTimestamp();

    const value = event.target.value === "Yes" ? true : false;
    setGradeValue(value);

    // Use a callback to ensure the state is fully updated before calling the API
    setAccommodationObject((prevState) => {
      const updatedObject = {
        ...prevState,
        dateCreated: props.student.dateCreated,
        dateModified: convertDate(timestamp),
        modifiedBy: `${props.facultyDemographics.firstName} ${props.facultyDemographics.lastName}`,
        isFinalGradePosted: value,
      };
      
      // console.log(updatedObject);
      handleUpdateStudentAccommodation(updatedObject);
      
      return updatedObject;
    });
  };

  const convertDate = (dateval) => {
      // Your initial date as a string
    const initialDateString = dateval;

    // Create a new Date object from the initial date string
    const initialDate = new Date(initialDateString.replace(" ", "T") + "Z");

    // Convert to the ISO format 'yyyy-MM-ddTHH:mm:ss.SSSZ'
    const formattedDate = initialDate.toISOString();

    // console.log(formattedDate);  // Output: "2024-11-14T11:47:44.000Z"
    return formattedDate;
  }

  const handleUpdateStudentAccommodation = async (accommodation) => {
    await updateStudentAccommodation(accommodation)
      .then((res) => {
        setSnackbarType("success");
        setSnackbarMessage("Updated student accommodation");
        setNoteHasChanged(false);
        setOpenSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarType("error");
        setSnackbarMessage("Failed to update student accommodation");
        setOpenSuccess(true);
      });
  };

  // =========================================================================================================================
  // Below code is to handle snackbars for successful or errored submissions
  const renderSnackbar = () => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={snackbarType === "success" ? 10000 : 13000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );

  const handleCloseSnackBar = (event, reason) => {
    setOpenSuccess(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  
  const handleRetryApiCall = () => {
    switch (snackbarMessage) {
      case "Failed to update student accommodation.":
        handleUpdateStudentAccommodation(accommodationObject);
        break;
      default:
        break;
    }
  };
  // =========================================================================================================================


const accommodations = [
  { text: "Ability to record lecture", page: 3 },
  { text: "Alternatively Formatted Textbooks and/or class materials", page: 4 },
  { text: "ASL Interpreter", page: 4 },
  { text: "Communication Access Real-Time Transcription (CART)", page: 11 },
  { text: "Email summary of in person or phone meetings", page: 5 },
  { text: "Extended Time for Individual Assignments", page: 6 },
  { text: "Extended Time for Competency Assessment", page: 7 },
  { text: "Extended Time for Exams & Quizzes", page: 8 },
  { text: "Frequent breaks", page: 10 },
  { text: "Individual or recorded presentations", page: 10 },
  { text: "Laptop and tablet use in classroom", page: 11 },
  { text: "Note-taking", page: 11 },
  { text: "Phone appt to clarify expectations", page: 12 },
  { text: "Preferential seating", page: 12 },
  { text: "Printed text", page: 12 },
  { text: "Provision of digital recording device", page: 3 },
  { text: "Quiet testing room", page: 13 },
  { text: "Writing Lab", page: 15 },
];

const findPageNumber = (accommodation) => {
  // Find the matching accommodation object
  const match = accommodations.find(
    (item) => item.text.toLowerCase() === accommodation.toLowerCase()
  );

  // Return the page number if a match is found, otherwise return null
  return match ? match.page : null;
};

  return (
    <>
      <TableRow
        key={props.student.studentIrn}
        className={
          hasNewAccommodation
            ? classes.newAccomContainer
            : classes.rowContainer
        }
      >
        <TableCell
          align="left"
          className={
            hasNewAccommodation
              ? classes.newAccomStickyColumn
              : classes.stickyColumn
          }
        >
          <Typography id={`studentFullName${props.key}-${props.courseOfferingId}`} className={classes.boldText}>
            {props.student.fullName !== "null null" ? props.student.fullName : ""}
          </Typography>
          {" "}
          {props.student.studentIrn}
        </TableCell>
        <TableCell align="left">{disabilityTypes}</TableCell>
        <TableCell align="left">
          {(newAccommodationTypes.length > 0 && accommodationTypesAcknowledged.length > 0) &&
            <div className={classes.newAccommodationIcon}>
              <Typography className={classes.newAccommodationIconText}>NEW</Typography>
            </div>
          }
         {newAccommodationTypes.length > 0 &&
          newAccommodationTypes.map((accommodation, index) => {
            // Find the matching accommodation
            const pageNum = findPageNumber(accommodation)
            const hrefValue = pageNum
              ? `${process.env.REACT_APP_STUDENT_ACCOMMODATION_DICTIONARY_URL}#page=${pageNum}`
              : "#"; // Default to "#" if no match is found

            return (
              <React.Fragment key={index}>
                <Button
                  disableRipple
                  href={hrefValue !== "#" ? hrefValue : null} // Nullifies href when it's "#"
                  target={hrefValue !== "#" ? "_blank" : undefined} // Open in new tab only if valid
                  rel={hrefValue !== "#" ? "noopener noreferrer" : undefined} // Security attribute only if link valid
                  role="button"
                  className={hrefValue === "#" ? classes.noLinkText : classes.linkText}
                  onClick={(e) => {
                    if (hrefValue === "#") {
                      e.preventDefault(); // Prevent default behavior for invalid links
                    }
                  }}
                  style={hrefValue === "#" ? { pointerEvents: "none", cursor: "default" } : {}}
                >
                  <Typography display="inline">
                    {`${accommodation}`}
                    {index < newAccommodationTypes.length - 1 && <span>{`;`}</span>}
                  </Typography>
                </Button>
              </React.Fragment>
            );
          })}
          {(newAccommodationTypes.length > 0 && accommodationTypesAcknowledged.length > 0) && <><br /><br /></>}
          {accommodationTypesAcknowledged.map((accommodation,index) => {
                const pageNum = findPageNumber(accommodation)
                const hrefValue = pageNum
                  ? `${process.env.REACT_APP_STUDENT_ACCOMMODATION_DICTIONARY_URL}#page=${pageNum}`
                  : "#"; // Default to "#" if no match is found

              return (
              <React.Fragment key={index}>
                <Button
                  disableRipple
                  href={hrefValue !== "#" ? hrefValue : null} // Nullifies href when it's "#"
                  target={hrefValue !== "#" ? "_blank" : undefined} // Open in new tab only if valid
                  rel={hrefValue !== "#" ? "noopener noreferrer" : undefined} // Security attribute only if link valid
                  role="button"
                  className={hrefValue === "#" ? classes.noLinkText : classes.linkText}
                  onClick={(e) => {
                    if (hrefValue === "#") {
                      e.preventDefault(); // Prevent default behavior for invalid links
                    }
                  }}
                  style={hrefValue === "#" ? { pointerEvents: "none", cursor: "default" } : {}}
                >
                  <Typography display="inline">
                    {`${accommodation}`}
                    {index < accommodationTypesAcknowledged.length - 1 && <span>{`;`}</span>}
                  </Typography>
                </Button>
              </React.Fragment>
            );
        })}
        </TableCell>
        <TableCell>
          <TextField
            id="description notes" // Unique ID for accessibility
            name="course"
            onChange={handleNotesChange}
            // placeholder={
            //   focused && noteValue.length === 0 ? "Type something..." : ""
            // }
            value={noteValue}
            placeholder={focused && noteValue.length === 0 ? "" : ""}
            variant="outlined"
            color="secondary"
            disabled={hasNewAccommodation}
            // aria-disabled={props.student.newAccommodation}
            InputProps={{
              maxLength: 4000,
              endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      position="end" 
                      className={classes.iconButton}
                      onClick={handleSaveNotes}
                      tabIndex={0}
                      role="button"
                      aria-label="Save notes"
                      aria-describedby={`studentFullName${props.key}-${props.courseOfferingId}`}
                      disabled={!noteHasChanged}
                    >
                      <SaveIcon aria-describedby={'save notes button'} className={noteHasChanged? classes.saveIcon: classes.saveIconDisabled}/>
                    </IconButton>
                  </InputAdornment>)
            }}
            className={
              hasNewAccommodation
                ? classes.textFieldGray
                : classes.textField
            }
            label={
              noteValue.length > 0 && !focused
                ? ""
                : focused
                ? noteValue.length === 0
                  ? "Optional field"
                  : "Optional field"
                : "Type something..."
            }
            InputLabelProps={{
              shrink: noteValue.length > 0 || focused, // Shrinks the label if there is a value or the field is focused
            }}
            onFocus={handleFocus}
            // onBlur={(e) => {
            //   handleBlur(e); // Call the existing blur handler
            //   handleNotesChange(noteValue);
            // }}
          />
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Select
            value={gradeValue ? "Yes" : "No"}
            onChange={handleGradeChange}
            displayEmpty
            variant="outlined"
            color="secondary"
            className={
              hasNewAccommodation
                ? classes.selectGray
                : classes.select
            }
            disabled={hasNewAccommodation}
          >
            <MenuItem
              value="Yes"
              aria-label="Yes"
              className={classes.menuItemFocused}
            >
              Yes
            </MenuItem>
            <MenuItem
              value="No"
              aria-label="No, negative"
              className={classes.menuItemFocused}
            >
              No
            </MenuItem>
          </Select>
        </TableCell>
        <TableCell align="center">
          {hasNewAccommodation ? (
            <>
              <div>
                <WarningIcon
                  className={classes.warningIcon}
                  aria-label="warning icon"
                  aria-hidden="false"
                />
              </div>
              <Button
                href="#"
                className={classes.linkText}
                role="button"
                onClick={handleOpenModal}
                disableRipple
              >
                <div>
                  <Typography>New student</Typography>
                  <Typography>accommodation</Typography>
                </div>
              </Button>
            </>
          ) : (
            <>
              <Button
                href="#"
                role="button"
                className={classes.linkText}
                aria-label="Review FNF link"
                onClick={handleOpenModal}
                disableRipple
              >
                <Typography>Review FNF</Typography>
              </Button>
            </>
          )}
        </TableCell>
      </TableRow>
      {openSuccess && renderSnackbar()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    personsWithName: state.personsWithName,
    facultyDemographics: state.demographics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonInfoWithName: (irn, personType) =>
      dispatch(getPersonInfoWithName(irn, personType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseTableRow);
