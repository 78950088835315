import { proxyClientInstance } from "../../wrappers/client";

export const getService = () => {
  const url = "/api/faculty/health";
  return proxyClientInstance.get(url);
};

export const getFacultySupervisor = (irn) => {
  const getUrl = `/api/faculty/v1/facultysupervisor?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const getFacultyCurrentCourses = (irn) => {
  const getUrl = `/api/faculty/v1/currentcourses?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const getFacultyWorkshops = (profileId) => { 
  const getUrl = `/api/faculty/v1/facultyWorkshops?profileId=${profileId}`;
  return proxyClientInstance.get(getUrl);
};

export const getFacultyScheduler = (irn) => {
  const getUrl = `/api/faculty/v1/schedulerinfo?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const getFacultyCoursesPreferences = (irn) => {
  const getUrl = `/api/faculty/v1/approvedcourses?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const updateFacultyCoursesPreferences = (irn, preferences) => {
  const putUrl = `/api/faculty/v1/preferences/courses/${irn}`;
  return proxyClientInstance.put(putUrl, preferences);
};

export const getFacultySolicitations = (irn) => {
  const getUrl = `/api/faculty/v1/solicitations?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const getAcceptedSolicitations = (irn) => {
  const getUrl = `/api/faculty/v1/acceptedcourses?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const getDeclinedSolicitations = (irn) => {
  const getUrl = `/api/faculty/v1/declinedcourses?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const acceptFacultySolicitations = (
  facultyScheduleId,
  statusDescription
) => {
  const putUrl = `/api/faculty/v1/solicitations/${facultyScheduleId}`;
  return proxyClientInstance.put(putUrl, {
    statusDescription: `${statusDescription}`,
  });
};

export const declineFacultySolicitations = (
  facultyScheduleId,
  reasonForDecline,
  statusDescription
) => {
  const putUrl = `/api/faculty/v1/solicitations/${facultyScheduleId}`;
  return proxyClientInstance.put(putUrl, {
    statusDescription: `${statusDescription}`,
    solicitReasonDesc: `${reasonForDecline}`,
  });
};

export const getFacultySolicitationsDeclineReasons = () => {
  const getUrl = `/api/faculty/v1/declinereasons`;
  return proxyClientInstance.get(getUrl);
};

export const getFacultyCourseHistory = (irn) => {
  const getUrl = `/api/faculty/v1/coursehistory?irn=${irn}`;
  return proxyClientInstance.get(getUrl);
};

export const postFacultyPortalFeedback = (request) => {
  const getUrl = `/api/faculty/v1/portalfeedback`;
  return proxyClientInstance.post(getUrl, request);
};

export const getWorkshops = (workshopsObject) => {
  const url = `/api/faculty/v1/workshops?fq=${workshopsObject.fq}&startDate=${workshopsObject.startDate}&endDate=${workshopsObject.endDate}&courseCode=${workshopsObject.courseCode}&courseTitle=${workshopsObject.courseTitle}&irn=${workshopsObject.irn}&username=${workshopsObject.username}`;
  return proxyClientInstance.get(url);
};

export const getWorkshopDetails = (courseCode) => {
  const url = `/api/faculty/v1/workshops/templates?fq=course_code&courseCode=${courseCode}`;
  return proxyClientInstance.get(url);
};

export const getWorkshopSections = (courseCode, startDate) => {
  const url = `/api/faculty/v1/workshops/sections?courseCode=${courseCode}&startDate=${startDate}`;
  return proxyClientInstance.get(url);
};

export const getWorkshopRoster = (coId) => {
  const url = `/api/faculty/v1/workshops/sections/${coId}/rosters`;
  return proxyClientInstance.get(url);
}

export const updateMember = (courseRoster, rosterId) => {
  const url = `/api/faculty/v1/workshops/rosters/${rosterId}`;
  console.log(url);
  return proxyClientInstance.put(url, courseRoster);
};

export const getSectionsForIrn = (irn) => {
  const url = `/api/faculty/v1/workshops/rosterSections/${irn}`;
  return proxyClientInstance.get(url);
};

export const registerParticipant = (startDate,endDate,courseCode,irn,userModifiedBy) => {
  const url = `/api/faculty/v1/workshops/participants?startDate=${startDate}&endDate=${endDate}&courseCode=${courseCode}&irn=${irn}&userModifiedBy=${userModifiedBy}`;
  return proxyClientInstance.post(url);
}; 

export const getSiteStripe = () => {
  const url = `/api/faculty/v1/siteStripe`;
  return proxyClientInstance.get(url);
};

export const getAccommodations = (getAcknowledgementObject) => {
  const url = `/api/faculty/v1/student-accommodations`;
  return proxyClientInstance.post(url, getAcknowledgementObject);
};

export const updateStudentAccommodation = (putAcknowledgementObject) => {
  const url = `/api/faculty/v1/student-accommodations/details`;
  return proxyClientInstance.put(url, putAcknowledgementObject);
};
export const getExtendedCourses = (irn) => {
  const url = `/api/faculty/v1/extendedCourses?irn=${irn}`;
  return proxyClientInstance.get(url);
};


export const postStudentAccommodation = (postAcknowledgmentObject) => {
  const url = `/api/faculty/v1/student-accommodations/acknowledgements`;
  return proxyClientInstance.post(url, postAcknowledgmentObject);
};
