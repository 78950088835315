import {GET_STUDENT_ACCOMMODATIONS} from "./actionTypes";

// eslint-disable-next-line 
export default (state = [], action) => {
    switch(action.type) {
        case GET_STUDENT_ACCOMMODATIONS :
            return action.payload;
        default:
            return state;  
    };
};